import * as styles from './FormatTemplate.module.scss'

import React from 'react'
import { Link, graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/Layout'
import PageMetaData from 'components/layout/PageMetaData'
import * as Typography from 'components/typography'

import Markdown from 'components/markdown/Markdown'

import Navigation from 'components/formats/Navigation'

interface Props extends PageProps<Queries.FormatTemplateQuery> {
  pageContext: any
}

const PostPage: React.FC<Props> = (props) => {
  const { data } = props
  const format = data.mdx

  if (format?.frontmatter == null) {
    return null
  }

  return (
    <Layout
      {...props.pageContext}
      accentColor={format.frontmatter.accentColor}
      includeNewsletterSignup
    >
      <div className={styles.back}>
        <Link to="/resources/formats/">&larr; Back to All Formats</Link>
      </div>

      <article>
        <div className={styles.header}>
          <h1>
            <Typography.PrimaryHeading>
              {format.frontmatter.title}
            </Typography.PrimaryHeading>
          </h1>
        </div>

        <div className={styles.content}>
          <Markdown>{format.body}</Markdown>
        </div>

        <Typography.Divider />
      </article>

      <Navigation currentID={format.id} />
    </Layout>
  )
}

export const query = graphql`
  query FormatTemplate($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        slug
        lastUpdatedDate
        description
        accentColor
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  const { data } = props
  const format = data.mdx

  if (format?.frontmatter == null) {
    return null
  }

  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: `${format.frontmatter.title} — Lucky Paper`,
        description: `${format.frontmatter.description} - From Lucky Paper's Guides to Cube Drafts`,
      }}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'Article',
        name: `${format.frontmatter.title} - Draft Guide`,
        headline: `${format.frontmatter.title} - Draft Guide`,
        url: props.pageContext.url,
        dateModified: format.frontmatter.lastUpdatedDate ?? undefined,
        abstract: format.frontmatter.description ?? undefined,
        publisher: {
          '@type': 'Organization',
          name: 'Lucky Paper',
          slogan: ' A love letter to Magic: the Gathering',
        },
        image: 'https://luckypaper.org/lucky-paper.jpg',
      }}
    />
  )
}

export default PostPage
