import * as styles from './Navigation.module.scss'

import React, { useMemo } from 'react'
import classNames from 'classnames'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { orderBy } from 'lodash'

import * as Typography from 'components/typography'

interface Props {
  currentID: string
}

const Navigation: React.FC<Props> = (props) => {
  const { currentID } = props

  const {
    allMdx: { formats },
  } = useStaticQuery<Queries.FormatsNavigationQuery>(
    graphql`
      query FormatsNavigation {
        allMdx(
          filter: {
            frontmatter: { published: { eq: true } }
            fileAbsolutePath: { regex: "/format-guide/" }
          }
        ) {
          formats: nodes {
            id
            body
            frontmatter {
              title
              slug
              editorsChoice
            }
          }
        }
      }
    `,
  )

  const sortedFormats = useMemo(
    () =>
      orderBy(
        formats,
        ['frontmatter.editorsChoice', 'frontmatter.title'],
        ['desc', 'asc'],
      ),
    [formats],
  )

  return (
    <div className={styles.container}>
      <Typography.TertiaryHeading>Draft Formats</Typography.TertiaryHeading>

      <div className={styles.formats}>
        {sortedFormats.map((format) => {
          if (format.frontmatter == null) {
            return null
          }

          return (
            <Link
              key={format.id}
              to={`/resources/formats/${format.frontmatter.slug}/`}
              className={classNames(styles.format, {
                'is-current': format.id === currentID,
              })}
            >
              {format.frontmatter.title}
            </Link>
          )
        })}
      </div>

      <p>
        Lucky Paper is a group of friends spending their free time creating
        resources, articles, and podcasts to support sandbox <em>Magic</em>{' '}
        formats. Have questions, comments, or corrections?{' '}
        <Link to="/about/">Get in touch!</Link>
      </p>
    </div>
  )
}

export default Navigation
